import { atom } from "jotai/vanilla";
import { omitBy } from "remeda";
import { NO_OVERRIDES, configOverridesAtom, type SiteConfigOverrides } from "./config.ts";
import { raiseToast } from "./modules/notifications/toast.tsx";
import { produce } from "./utils/immer.ts";
import { atomAction } from "./utils/jotai/atomAction.ts";

/** Setter updates the config overrides, while omitting `undefined` values from the object (to allow removing overrides easily) */
export const updateConfigOverrideAtom = atom(
    (get) => get(configOverridesAtom),
    (_get, set, update: (configDraft: SiteConfigOverrides) => void) => {
        //
        set(configOverridesAtom, (prev) => omitBy(produce(prev, update), (v) => v === undefined));
    },
);

export const resetConfigOverridesTitle = "Reset config overrides";

export const resetConfigOverridesAtom = atomAction((_, set) => {
    set(configOverridesAtom, NO_OVERRIDES);

    raiseToast({ message: "Debug overrides reset", type: "success", icon: "i-lucide:bug", noNotification: true });
});
